import React from 'react';
import {Col} from 'antd';
import { Link } from 'react-router-dom';
import { useWindowWidth } from '@react-hook/window-size';
import './article-card.css';
import DocSvg from "../../assets/document.svg";
import DocSvgAdmin from "../../assets/document-admin.svg";
import { EyeFilled } from '@ant-design/icons';

const ArticleCard = ({item, id, items}) => {
    const onlyWidth = useWindowWidth();
    const divItems = items.map(i => <div key={i.id} className='article-card-subitem'>
            <Link to={`article/${i.alias ? i.alias : i.id}`}>{i.name}</Link>
            {item?.admin ? <EyeFilled style={{color: '#f55'}} /> : null}
        </div>);
    return <Col span={onlyWidth >= 1400 ? 8 : 24}>
        <div className='article-card'>
            <img src = {item.admin ? DocSvgAdmin : DocSvg} height={40} alt='Документ'/>
            <div className='article-card-content'>
                <div className='article-card-title' ><Link to={`article/${item.alias ? item.alias : item.id}`}>{item.name}</Link></div>
                <div className='article-card-description'>{item.description}</div>
                {divItems}
            </div>
        </div>
    </Col>;
};

export default ArticleCard;