import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Input, Form, Modal, Button, message } from 'antd';
import { useWindowWidth } from '@react-hook/window-size';
import { serverFetch } from '../../server';
import './account.css';

const Account = ({ ...props }) => {
    const user = useSelector((s) => s.user);
    const { open, onClose } = props;
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [client, setClient] = useState({});
    const onlyWidth = useWindowWidth();

    useEffect(() => {
        getData();
        return () => {
            close();
        };
    }, []);

    const getData = () => {
        setLoading(true);

        serverFetch('account/info', { method: 'GET' }, user)
            .then((data) => {
                setClient(data);
                form.setFieldsValue({...data});
                setLoading(false);
            })
            .catch((e) => {
                message.error('Ошибка получения пользователя');
                setLoading(false);
            });
    };

    const close = () => {
        setLoading(false);
        form.resetFields();
        onClose();
    };

    const onFinish = () => {
        setLoading(true);
        serverFetch(`account`, { method: 'PUT', bodyData: client }, user)
            .then(() => {
                close();
            })
            .catch((e) => {
                setLoading(false);
                e.UserMessage && message.error(e.UserMessage);
            });
    };

    const onConfirmEmail = () => {
        serverFetch(`account/confirmemail`, { method: 'POST', bodyData: client }, user)
            .then(() => {
                message.success('На вашу почту отправлено письмо для подтверждения');
            })
            .catch((e) => {
                e.UserMessage && message.error(e.UserMessage);
            });
    };

    const onValuesChange = (value) => {
        setClient({ ...client, ...value });
    };

    return (
        <Modal
            forceRender
            open={open}
            showTitle={false}
            closable={false}
            closeOnOutsideClick={false}
            footer={null}
            width={540}
            onCancel={() => onClose()}
            bodyStyle={{ margin: '-4px 16px 0' }}
        >
            <div className='auth-title'>Мои данные</div>

            <Form
                colon={false}
                form={form}
                labelCol={{ span: 7 }}
                wrapperCol={{ span: 17 }}
                onValuesChange={onValuesChange}
                onFinish={onFinish}
            >
                <Form.Item label='ФИО' name='name'>
                    <Input size='middle' name='name' disabled={loading} />
                </Form.Item>
                <Form.Item label='Телефон'>
                    <>{client.phone}</>
                </Form.Item>
                {/*
                <Form.Item
                    label='Почта'
                    disabled={loading}
                    name='email'
                    validateFirst={true}
                    rules={[{ type: 'email', message: 'Неверный формат Email' }]}
                >
                    <Input name='email' disabled={loading} />
                </Form.Item>
                <Form.Item label={<span></span>}>
                    <div className='btn-container' style={{ margin: 0 }}>
                        <div className='auth-btn' style={{ marginTop: 5 }}>
                            {client.emailConfirmed ? (
                                <span className='active'>Подтверждена</span>
                            ) : (
                                <span className='inactive'>Не подтверждена</span>
                            )}
                        </div>
                        <Button className='auth-btn' size='middle' disabled={loading} onClick={() => onConfirmEmail()}>
                            Подтвердить
                        </Button>
                    </div>
                            </Form.Item>*/}

                <Form.Item label='' wrapperCol={{ span: 24 }}>
                    На указанную почту было отправлено письмо. Для подтверждения пройдите по ссылке из этого письма.
                </Form.Item>
                <Form.Item>
                    <div className='btn-container'>
                        <Button className='auth-btn' size='middle' onClick={() => onClose()} disabled={loading}>
                            Назад
                        </Button>
                        <Button className='auth-btn' size='middle' loading={loading} htmlType='submit'>
                            Сохранить
                        </Button>
                    </div>
                </Form.Item>
                <label className='prompt'>Нажимая на кнопку "Сохранить", вы даете согласие на обработку персональных данных и соглашаетесь c условиями и правилами сервиса</label>
            </Form>
        </Modal>
    );
};

export default Account;