import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter, useHistory } from 'react-router';
import { Button, Card, Row, Tag, message } from 'antd';
import { useWindowWidth } from '@react-hook/window-size';
import { serverFetch } from '../../server';

import ArticleCard from '../article-card';

import './home.css';
import Space from '../space/space';
import { PlusOutlined } from '@ant-design/icons';
import ArticleNew from '../article/article-new';

const Home = () => {
    const { Meta } = Card;

    const d = useDispatch();

    const emptyItems = [{ id: 0, name: '', description: '' }];

    const history = useHistory();
    const user = useSelector((s) => s.user);
    const company = useSelector((s) => s.company);
    const onlyWidth = useWindowWidth();

    const [items, setItems] = useState(emptyItems);
    const [loading, setLoading] = useState(false);
    const [adding, setAdding] = useState(false);


    useEffect(() => {
        company && company.id > 0 && getData();
    }, [company]);

    const getData = () => {
        setLoading(true);

        serverFetch('article/get_group/0', { method: 'GET' }, user)
            .then((data) => {
                setItems(data);
                setLoading(false);
            })
            .catch((e) => {
                message.error('Ошибка получения статей');
                setLoading(false);
            });
    };


    const onAdd = () => {
        //setEditParentId(article.parentId);
        setAdding(true);
    }

    const onCloseEdit = () => {
        setAdding(false);
        getData();
    }

    const groups = [...new Set(items.map(item => item.spaceName))];

    let renderItems = [];


    groups.forEach(i => {
        renderItems.push(<Space title={i}/>);
        renderItems.push(<Row>{items.filter(i2 => i2.spaceName == i && i2.parentId == 0).map(item => {
            const childItems = items.filter(i3 => i3.parentId == item.id);
            return <ArticleCard item={item} id={item.id} key={item.id} items={childItems}/>
        }
        )}</Row>);
    });

    const divAdding = adding ? <ArticleNew onClose={onCloseEdit} parentId={0}/> : null;

    return (
        <>
        <h1>Главная {user && user.admin ? <Button type="link" shape="circle" icon={<PlusOutlined />} onClick={onAdd} /> : null}</h1>
        
        {renderItems}
        {divAdding}
        </>
    );
};

export default withRouter(Home);
