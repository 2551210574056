import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Input, Form, Modal, Button, message } from 'antd';
import { serverFetch } from '../../server';
import { translit } from '../../extensions/utils';

const ArticleNew = ({ ...props }) => {
    const user = useSelector((s) => s.user);
    const { onClose, parentId } = props;
    const [article, setArticle] = useState({});
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    const onFinish = () => {
        setLoading(true);
        serverFetch(`article`, { method: 'PUT', bodyData: { alias: translit(article.name).toLowerCase(), parentId, ... article} }, user)
            .then(() => {
                close();
            })
            .catch((e) => {
                setLoading(false);
                e.UserMessage && message.error(e.UserMessage);
            });
    };

    const close = () => {
        setLoading(false);
        form.resetFields();
        onClose();
    };

    const onValuesChange = (value) => {
        setArticle({ ...article, ...value });
    };
    return (
        <Modal
            forceRender
            open={true}
            showTitle={false}
            closable={false}
            closeOnOutsideClick={false}
            footer={null}
            width={540}
            onCancel={() => onClose()}
            bodyStyle={{ margin: '-4px 16px 0' }}
        >
            <div className='auth-title'>Мои данные</div>
            <Form
                colon={false}
                form={form}
                labelCol={{ span: 7 }}
                wrapperCol={{ span: 17 }}
                onValuesChange={onValuesChange}
                onFinish={onFinish}
            >
                <Form.Item label='Название' name='name'>
                    <Input size='middle' name='name' disabled={loading}/>
                </Form.Item>
                <Form.Item label='Порядок' name='idx'>
                    <Input size='middle' name='idx' disabled={loading}/>
                </Form.Item>
                <Form.Item label='Псевдоним' name='alias'>
                    <Input size='middle' name='alias' disabled={loading}/>
                </Form.Item>
                <Form.Item>
                    <div className='btn-container'>
                        <Button className='auth-btn' size='middle' onClick={() => onClose()} disabled={loading}>Назад</Button>
                        <Button className='auth-btn' size='middle' loading={loading} htmlType='submit'>Сохранить</Button>
                    </div>
                </Form.Item>
            </Form>
        </Modal>
    )
}
export default ArticleNew;