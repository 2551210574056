import { message } from 'antd';

export const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

function value4query(key, value) {
    if (value === null || value === undefined) {
        return '';
    }

    return value; //encodeURIComponent(value);
}

export async function serverFetch(address, options, user) {
    options = options || {};
    let url = new URL(`${apiBaseUrl}/api/${address}`);
    Object.keys(options.queryParams || {}).forEach((key) => {
        if (Array.isArray(options.queryParams[key])) {
            options.queryParams[key].map((value) => url.searchParams.append(key, value4query(key, value)));
        } else {
            url.searchParams.append(key, value4query(key, options.queryParams[key]));
        }
    });

    let init = {
        method: 'GET',
        ...options,
        headers: options.body ? new Headers() : new Headers({ accept: 'application/json', 'Content-Type': 'application/json-patch+json' }),
    };

    if (user) {
        init.headers.append('SessionId', user.id ? user.id : '');
    }

    init.headers.append('Domain', window.location.hostname);

    if (options.bodyData != null) {
        init.body = JSON.stringify(options.bodyData);
    }

    if (options.headers) {
        Object.keys(options.headers).forEach((key) => {
            init.headers.append(key, options.headers[key]);
        });
    }

    let response;
    try {
        response = await fetch(url, init);
    } catch (exc) {
        console.log('error fetch', exc);
        console.error(exc);
        message.error('Извините, сервер недоступен');
        throw new Error('Error parsing response body');
    }

    if (response.ok && response.status == 204) {
        return;
    }

    let responseContentPromise;
    if (!response.ok || options.rawResponse) {
        responseContentPromise = response.text();
    } else if (options.download || options.blob) {
        responseContentPromise = response.blob();
    } else {
        responseContentPromise = response.json();
    }

    console.log('server wait content');
    let content;
    try {
        content = await responseContentPromise;
        console.log('server content', content);
    } catch (exc) {
        console.error(exc);
        message.error('Извините, сервер недоступен');
        throw new Error('Error parsing response body');
    }

    if (response.ok) {
        return content;
    }

    if (!content) {
        content = {
            status: response.status,
            message: response.statusText,
        };
    } else if (typeof content == 'string') {
        try {
            content = JSON.parse(content);
            console.log('contentParsed', content);
        } catch {
            message.error('Извините, сервер недоступен');
            // ignore
        }
    }
    throw content;

    // return fetch(url, init);
}
