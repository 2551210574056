export const initialState = {
    user: null,
    company: null,
    currentNavMenu: 'home',
};

export const reducer = (state, action) => {
    switch (action.type) {
        case 'COMPANY_LOADED':
            return {
                ...state,
                company: action.payload,
            };

        case 'USER_LOADED':
            return {
                ...state,
                user: action.payload,
            };

        case 'SET_CURRENT_NAV_MENU':
            return {
                ...state,
                currentNavMenu: action.payload,
            };

        default:
            return state;
    }
};
