import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useWindowWidth } from '@react-hook/window-size';

import { Input, Form, Modal, Button, message } from 'antd';
import { MaskedInput } from 'antd-mask-input';

import { userLoaded } from '../../redux/actions';

import { serverFetch } from '../../server';

import './auth.css';

const Auth = ({ ...props }) => {
    const d = useDispatch();

    const { open, onClose } = props;

    const [form] = Form.useForm();

    const [signinLoading, setSigninLoading] = useState(false);
    const [signupLoading, setSignupLoading] = useState(false);
    const [authData, setAuthData] = useState({});
    const onlyWidth = useWindowWidth();

    {/*
    useEffect(() => {
        document.addEventListener('keydown', onKeyDown, false);
        return () => {
            document.removeEventListener('keydown', onKeyDown, false);
            setSigninLoading(false);
        };
    }, []);*/}

    useEffect(() => {
        if (!open) form.resetFields();
    }, [open]);

    {/*}
    const onKeyDown = (e) => {
        if (e.keyCode === 13) {
            form.submit();
        }
    };*/}

    const onFinish = () => {

        let data = {
            phone: authData.phone,
            password: authData.password,
        };
        
        console.log('onSignin', data);

        if (authData.isSignIn) {
            signin(data);
        } else {
            signup(data);
        }
    };

    const signin = (data) => {
        setSigninLoading(true);
        serverFetch(`account/signin`, { method: 'POST', bodyData: data })
            .then((data) => {
                if (data) {
                    d(userLoaded(data));
                    setSigninLoading(false);
                    onClose();
                } else {
                    message.error('Ошибка авторизации');
                    setSigninLoading(false);
                }
            })
            .catch((e) => {
                setSigninLoading(false);
                e.UserMessage && message.error(e.UserMessage);
            });
    };

    const signup = (data) => {
        setSignupLoading(true);

        serverFetch(`account/signup`, { method: 'POST', bodyData: data })
            .then((data) => {
                setSignupLoading(false);
                //onClose();
                message.success('Ваш пароль для входа отправлен на указанный номер телефона.');
            })
            .catch((e) => {
                setSignupLoading(false);
                e.UserMessage && message.error(e.UserMessage);
            });
    };

    const onValuesChange = (value) => {
        setAuthData({ ...authData, ...value });
    };

    const onSignin = () => {
        setAuthData({ ...authData, isSignIn: true });
        form.validateFields();
        form.submit();
    };

    const onSignup = () => {
        setAuthData({ ...authData, isSignIn: false });
        form.validateFields();
        form.submit();
    };

    return (
        <Modal
            forceRender
            open={open}
            title=''
            closable={false}
            footer={null}
            width={520}
            onCancel={() => onClose()}
            bodyStyle={{ margin: '-4px 20px 0' }}
        >
            <div className='auth-title'>Войти или зарегистрироваться</div>

            <Form
                colon={false}
                form={form}
                onFinish={onFinish}
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                onValuesChange={onValuesChange}
            >
                <Form.Item
                    requiredMark='optional'
                    label='Телефон'
                    name='phone'
                    rules={[{ required: true, message: 'Пожалуйста введите номер телефона' }]}
                >
                    <MaskedInput size='middle' mask={'+70000000000'} name='phone' disabled={signinLoading || signupLoading}/>
                </Form.Item>

                <Form.Item label='Код доступа' name='password'>
                    <Input.Password size='middle' name='password' disabled={signinLoading || signupLoading} />
                </Form.Item>

                <Form.Item wrapperCol={{ offset: onlyWidth > 744 ? 6 : 0}}>
                    <div className='btn-container'>
                        <Button className='auth-btn' size='middle' onClick={() => onClose()} disabled={signinLoading}>
                            Назад
                        </Button>
                        <Button
                            className='auth-btn'
                            size='middle'
                            disabled={signupLoading}
                            loading={signinLoading}
                            onClick={() => onSignin()}
                        >
                            Войти
                        </Button>
                    </div>
                </Form.Item>

                <Form.Item />

                <Form.Item wrapperCol={{ offset: onlyWidth > 744 ? 6 : 0 }}>
                    <Button
                        type='link'
                        size='middle'
                        onClick={() => onSignup()}
                        loading={signupLoading}
                        disabled={signinLoading}
                        block={true}
                    >
                        Получить код доступа
                    </Button>
                    <div className='prompt'>Новый код доступа будет отправлен в СМС</div>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default Auth;
