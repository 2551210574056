import React, {useEffect, useState} from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { serverFetch } from '../../server';
import { Layout, Space, AutoComplete, message, Spin  } from 'antd';

import './header.css';

const Header = () => {
    const history = useHistory();
    const { Header } = Layout;
    const [value, setValue] = useState();
    const [options, setOptions] = useState([]);
    const user = useSelector((s) => s.user);
    const [searching, setSearching] = useState(false);
    const isFrame = window.self !== window.top;

    const onSearch = (text) => {
        if (text && text.length > 3) {
            setSearching(true);
            serverFetch(`article/search/${text}`, null, user)
                .then((data) => {
                    const newOptions = data.map(i => {
                        const spaceName = i.spaceName ? (" (" + i.spaceName + ")") : "";
                        return {key: i.id, label: i.name + spaceName, value: '' + i.id
                    }});
                    setOptions(newOptions);
                    setSearching(false);
                })
                .catch((e) => {
                    setSearching(false);
                    message.error('Ошибка поиска статьи');
                })
        }
        else {
            setOptions([]);
        }
    }

    const onSelect = (value) => {
        history.push(`/article/${value}`);
        setValue("");
    }

    const onChange = (value) => {
        setValue(value);
    }

    const divSearching = searching && <Spin />

    return (isFrame ? <div /> : 
        <>
            <Header className='header'>
                <div className='header-body'>
                <Space>
                    <AutoComplete className='search-input'
                        onSearch={onSearch}
                        onSelect={onSelect}
                        onChange={onChange}
                        options={options} 
                        placeholder="Введите текст для поиска в статьях"
                        value={value} 
                    />
                    {divSearching}
                {/*<Input placeholder="Введите текст для поиска в статьях" prefix={<SearchOutlined style={{color: "#00A8DD"}}/>}/>*/}
                </Space>
                </div>
            </Header>
        </>
    );
};

export default Header;
