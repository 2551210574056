import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Image, Dropdown, Button, message } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import Auth from '../auth/auth.js';
import Account from '../account/account';
import { userLoaded } from '../../redux/actions';
import { serverFetch } from '../../server';
import { Link } from 'react-router-dom';
import './nav-menu.css';

const iconRU = require('../../assets/ru.png');
const iconEN = require('../../assets/en.png');
const iconCH = require('../../assets/ch.png');
const iconES = require('../../assets/es.png');
const iconTR = require('../../assets/tr.png');

const NavMenu = () => {
    const d = useDispatch();
    const current = useSelector((s) => s.currentNavMenu);
    const user = useSelector((s) => s.user);
    const [showSignin, setShowSignin] = useState(false);
    const [showAccountInfo, setShowAccountInfo] = useState(false);
    const company = useSelector((s) => s.company);
    const iconLogo = require('../../assets/logo.png');    
    const isFrame = window.self !== window.top;

    const logout = () => {
        serverFetch(`account/logout`, { method: 'POST' })
            .then((data) => {
                d(userLoaded());
            })
            .catch((e) => {
                message.error('Ошибка выхода из системы');
            });
    };

    useEffect(() => {
        let key = 'home';
        if (window.location.pathname !== '/') {
            key = window.location.pathname.slice(1);
        }
    }, [user]);

    const items = [
        {
            key: '1',
            label: (
                <Button type='link' className='account-item-btn' onClick={() => setShowAccountInfo(true)} size='middle'>
                    Мои данные
                </Button>
            ),
        },
        {
            key: '2',
            label: (
                <Button type='link' className='account-item-btn' onClick={() => logout()} size='middle'>
                    Выход
                </Button>
            ),
        },
    ];

    // const divLng = <div className='nav-menu-lng'>
    //     <Image src={iconRU} preview={false}/>
    //     <Image src={iconEN} preview={false}/>
    //     <Image src={iconCH} preview={false}/>
    //     <Image src={iconES} preview={false}/>
    //     <Image src={iconTR} preview={false}/>
    // </div>

    return (isFrame ? <div /> : 
        <>
            <div className='nav-menu-container'>
                <div className='nav-menu-title'>
                    <Link to="/">
                        <Image preview={false} src={company?.logoFileName ?? iconLogo} style={{marginBottom: -16, paddingRight: 16}}/>
                    </Link>База знаний {company && company.name}
                </div>
                {/* {divLng} */}
                {user ? (
                    <Dropdown menu={{ items: items }} placement='bottomRight'>
                        <Button type='link' className='account-btn' size='middle' icon={<UserOutlined />}>
                            {user.userName || user.userLogin}
                        </Button>
                    </Dropdown>
                ) : (
                    <Button
                        type='link'
                        className='account-btn'
                        onClick={() => setShowSignin(true)}
                        size='middle'
                        icon={<LockOutlined />}
                    >
                        Личный кабинет
                    </Button>
                )}
            </div>
            {showSignin && <Auth open={showSignin} onClose={() => setShowSignin(false)} />}
            {showAccountInfo && <Account open={showAccountInfo} onClose={() => setShowAccountInfo(false)} />}
        </>
    );
};

export default NavMenu;