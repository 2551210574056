import { createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { initialState, reducer } from './reducer';

function configureStore() {
    const store = createStore(
        persistReducer(
            {
                key: 'root',
                storage,
                whitelist: ['user', 'company', 'currentNavMenu'],
            },
            reducer
        ),
        initialState
    );

    console.log('initialState', store.getState());

    const persistor = persistStore(store, null, () => {
        // if you want to get restoredState
        console.log('restoredState', store.getState());
    });

    return { store, persistor };
}

export default configureStore;