export const companyLoaded = (company) => {
    return {
        type: 'COMPANY_LOADED',
        payload: company,
    };
};

export const userLoaded = (user) => {
    return {
        type: 'USER_LOADED',
        payload: user,
    };
};

export const setCurrentNavMenu = (menuKey) => {
    return {
        type: 'SET_CURRENT_NAV_MENU',
        payload: menuKey,
    };
};
