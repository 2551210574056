import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Layout, ConfigProvider, message, Space } from 'antd';
import ru_RU from 'antd/locale/ru_RU';
import 'dayjs/locale/ru';
import { useWindowWidth } from '@react-hook/window-size';
import WebFont from 'webfontloader';
import Header from './components/header/header';
import NavMenu from './components/nav-menu/nav-menu';
import Home from './components/home/home';
import Article from './components/article';
import { companyLoaded, userLoaded } from './redux/actions';
import { setStyleProperty } from './extensions/utils';
import { serverFetch } from './server';
import { Footer } from 'antd/lib/layout/layout';
import CookieConsent from "react-cookie-consent";

const App = () => {
    const { Content } = Layout;
    const d = useDispatch();
    const user = useSelector((s) => s.user);
    const onlyWidth = useWindowWidth();
    const isFrame = window.self !== window.top;

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['PT Sans'],
            },
        });

        serverFetch(`company`, { method: 'GET' })
            .then((data) => {
                if (data) {
                    d(companyLoaded(data));

                    //init company colors
                    setStyleProperty('--default-color', data.primaryColor);
                    setStyleProperty('--primary-color', data.primaryColor);
                    setStyleProperty('--hover-color', data.primaryColor);
                    setStyleProperty('--active-color', data.primaryColor);
                    document.title = `${data.name} (${process.env.REACT_APP_VERSION})`;
                } else {
                    message.error('Ошибка загрузки компании');
                }
            })
            .catch((e) => {
                e.UserMessage && message.error(e.UserMessage);
            });
    }, []);

    useEffect(() => {
        console.log("Пользователь изменен", user);
        if (user != null) {
            console.log("запуск проверки сессии");
            let queryParams = {
                sessionId: user.id,
            };
            serverFetch('account/checksession', { method: 'GET', queryParams: queryParams }, null)
            .then((data) => console.log("Сессия проверена успешно", data))
            .catch(() => {
                console.log('Ошибка проверки сессии');
                d(userLoaded())
            });
        }
    }, [user]);


    const linkStyle = {cursor: 'pointer', color: 'white'};
    return (
        <Router>
            <ConfigProvider locale={ru_RU}>
                <Layout style={{ minHeight: '100vh' }}>
                    <div className='header-menu'>
                        <div className='header-container'>
                            <NavMenu />
                        </div>
                    </div>
                    <div className='header-container'>
                        <Header />
                    </div>
                    <Content>
                        <div className={isFrame ? '' : 'content-container'}>
                            <div className='block-content'>
                                <Switch>
                                    <Route exact path='/' component={Home} />
                                    <Route path='/article/:id?' component={Article} />
                                </Switch>
                            </div>
                        </div>
                        <div id="main-footer-article" style={{height: 1}}/>
                    </Content>
                    {!isFrame && <Footer><Space
                            size={onlyWidth >= 744 ? 'middle' : 'small'}
                            direction={onlyWidth >= 744 ? 'horizontal' : 'vertical'}
                            style={{ fontSize: 14 }}
                        >
                            <a href='https://itokna.net' target='_blank'>
                                <div style={linkStyle}>(с) Компания МИ-СОФТ 2004 - 2024</div>
                            </a>
                    </Space></Footer>}
                    {!isFrame && <CookieConsent buttonText="Принять">Мы используем файлы cookie чтобы обеспечить Вам наилучшую работу на нашем веб-сайте. Продолжая использовать этот сайт, Вы даете согласие на использование файлов cookie</CookieConsent>}
                </Layout>
            </ConfigProvider>
        </Router>
    );
};

export default App;

