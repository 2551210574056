import React from "react";
import './space.css';
import { Tag } from "antd";

const Space = ({...props}) => {
    const {title} = props;


    return <div>
        <Tag color='geekblue'>{title}</Tag>
    </div>
}

export default Space;
