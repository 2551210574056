import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { withRouter, useParams } from 'react-router';
import { serverFetch } from '../../server';
import {Col, Row, message, Breadcrumb, Button, Input, Tag, Checkbox, Select } from 'antd';
import parse from 'html-react-parser';
import { Link, useHistory } from 'react-router-dom';
import { HomeOutlined, EditOutlined, SaveOutlined, CloseOutlined, ThunderboltOutlined, PlusOutlined, EyeFilled, DeleteFilled } from '@ant-design/icons';
import { idNameToOptions, translit } from '../../extensions/utils';
import { useWindowWidth } from '@react-hook/window-size';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ArticleNew from './article-new';
import './article.css';
const { TextArea } = Input;

const Article = () => {
    const {id} = useParams();
    const [loading, setLoading] = useState(false);
    const [item, setItem] = useState();
    const [related, setRelated] = useState([]);
    const [children, setChildren] = useState([]);
    const [breadItems, setBreadItems] = useState([]);
    const user = useSelector((s) => s.user);
    const [editing, setEditing] = useState(false);
    const [body, setBody] = useState("");
    const [name, setName] = useState("");
    const [alias, setAlias] = useState("");
    const [idx, setIdx] = useState(0);
    const [admin, setAdmin] = useState(false);
    const [adding, setAdding] = useState(false);
    const [article, setArticle] = useState();
    const [editParentId, setEditParentId] = useState();
    const [spaces, setSpaces] = useState([]);
    const onlyWidth = useWindowWidth();
    const [spaceId, setSpaceId] = useState();
    const inputFileRef = useRef(null);
    const [editHtml, setEditHtml] = useState(false);
    let history = useHistory();
    const isFrame = window.self !== window.top;

    const getItem = () => {
        setLoading(true);
        serverFetch(`article/${id}`, null, user)
            .then((data) => {
                setArticle(data.current);
                setBody(data.current.body || '');
                setName(data.current.name);
                setAlias(data.current.alias);
                setIdx(data.current.idx);
                setAdmin(data.current.admin);
                setSpaceId(data.current.spaceId);
                setItem(data.current);
                setRelated(data.related);
                setBreadItems(data.path);
                setChildren(data.children);
                setLoading(false);
            })
            .catch((e) => {
                console.log(e);
                message.error('Ошибка получения статьи');
                setLoading(false);
            });
        serverFetch(`space`, null, user)
            .then((data) => setSpaces(data))
            .catch((e) => message.error('Ошибка получения пространств'));
    }
    useEffect(() => getItem(), [id, user]);

    const onSave = () => {
        setLoading(true);
        const al = alias || translit(name).toLocaleLowerCase();
        serverFetch(`article/save`, {method: 'PUT', bodyData: {...item, id: item.id, name: name, body: body, alias: al, idx, admin, spaceId}}, user)
            .then(() => {
                setEditing(false);
                getItem();
            })
            .catch((e) => {
                e.UserMessage && message.error(e.UserMessage);
            });
        ;
    }

    const onSaveBody = (body2) => {
        setLoading(true);
        const al = alias || translit(name).toLocaleLowerCase();
        serverFetch(`article/save`, {method: 'PUT', bodyData: {...item, id: item.id, name: name, body: body2, alias: al, idx, admin, spaceId}}, user)
            .then(() => {
                setLoading(false);
                setEditing(false);
                getItem();
            })
            .catch((e) => {
                setLoading(false);
                e.UserMessage && message.error(e.UserMessage);
            });
        ;
    }

    const onTranslit = () => setAlias(translit(name).toLowerCase());

    const onDelete = () => {
        if (window.confirm("Удалить?")){
            setLoading(true);
            serverFetch(`article/${item.id}`, {method: 'DELETE' }, user)
                .then(() => {
                    setEditing(false);
                    setLoading(false);
                    history.replace(`/article/${item.parentId}`);
                })
                .catch((e) => {
                    setLoading(false);
                    e.UserMessage && message.error(e.UserMessage);
                });
        }
    }
    
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();    
        reader.onload = (e) => {
          const content = e.target.result;
          onSaveBody(content);
        };    
        reader.readAsText(file);
    };

    const handleOpenDialog = () => inputFileRef.current.click();
    
    const divFileLoad = <div>
        <input type="file" ref={inputFileRef} onChange={handleFileChange} style={{ display: 'none' }} />
        <button onClick={handleOpenDialog}>Загрузить из файла</button>
    </div>

    const titleText = loading ? 'Загрузка...' : item && item.name;
    const divTitle = editing ? <Input value={name} onChange={(e) => setName(e.target.value)}/> : <div>{titleText} {user && user.admin && item && "#" + item.id}</div>;

    const divBody = editing ? (editHtml ? 
        <TextArea autoSize={{ minRows: 20 }} value={body} onChange={(e) => setBody(e.target.value)} /> 
        : <CKEditor editor={Editor} data={body} onChange={(event,editor) => setBody(editor.getData())} config={{height: '80vh'}} />) 
    : parse(body);

    const divRelated = related && related.map(i => 
        <div className='article-related-link' key={i.id}>
            <Link to={`/article/${i.alias ? i.alias : i.id}`}> {i.name}</Link>
            {i.admin ? <EyeFilled style={{color: '#f55'}} /> : null}
        </div>);
    const divChildren = children && children.map(i => 
        <div className='article-related-link' key={i.id}>
            <Link to={`/article/${i.alias ? i.alias : i.id}`}> {i.name}</Link>
            {i.admin ? <EyeFilled style={{color: '#f55'}} /> : null}
        </div>);
    const divBreads = breadItems.map(i => <Breadcrumb.Item key={i.id}><Link to={`/article/${i.id}`}>{i.name}</Link></Breadcrumb.Item>);
    const hasChildred = id && children && children.length > 0;
    const btnEdit = user && user.admin ? ( editing ? <>
                {!loading && <Button type="link" shape="circle" icon={<SaveOutlined />} onClick={onSave}/>}
                {!loading && <Button type="link" shape="circle" icon={<CloseOutlined />} onClick={() => setEditing(false)}/>}
                {!loading && <Button type='link' shape="circle" icon={<DeleteFilled style={{color: hasChildred ? 'gray' : 'red'}} />} disabled={hasChildred} onClick={onDelete}/>}
            </> 
            : !loading && <>
                {divFileLoad}
                <Button type="link" shape="circle" icon={<EditOutlined />} onClick={() => {
                    setEditing(true);
                    setEditHtml(false);
                }} />
                <Button className='btn-img' onClick={() => {
                    setEditing(true);
                    setEditHtml(true);
                }}><img src='/images/html.svg' /></Button>
            </>
    ) : null;
    const divAlias = editing && <div className='article-title'>
            <Input value={alias} onChange={(e) => setAlias(e.target.value)}/>
            <Button type="link" shape="circle" icon={<ThunderboltOutlined />} onClick={onTranslit} />
        </div>
    const divIdx = editing && <div className='article-title'>
        <Input value={idx} onChange={(e) => setIdx(e.target.value)}/>
    </div>
    const divAdmin = editing && <div className='article-title'>
        <Checkbox checked={admin} onChange={(e) => setAdmin(e.target.checked)}>Доступ ограничен</Checkbox>
    </div>
    const divSpaces = editing && spaces && spaces.length > 0 && <div className='article-title'>
        <Select style={{width : '100%'}} value={spaceId} onChange={(e) => setSpaceId(e)}>
            {idNameToOptions(spaces)}
        </Select>
    </div>

    const onAdd = () => {
        setEditParentId(article.parentId);
        setAdding(true);
    }

    const onAddChild = () => {
        setEditParentId(article.id);
        setAdding(true);
    }

    const colSide = <Col span={onlyWidth >= 1400 ? 4 : 24} >
        <div className='article-side' style={{display: (children && children.length > 0) || (user && user.admin)  ? "block" : "none"}}>
                <div className='article-related-title'>Подробнее {user && user.admin ? <Button type="link" shape="circle" icon={<PlusOutlined />} onClick={onAddChild} /> : null}</div>
                {divChildren}
        </div>
        <div className='article-side' style={{display: (related && related.length > 0) || (user && user.admin) ? "block" : "none"}}>
            <div className='article-related-title'>Связанные статьи {user && user.admin ? <Button type="link" shape="circle" icon={<PlusOutlined />} onClick={onAdd} /> : null}</div>
            {divRelated}
        </div>
    </Col>

    const colBody = <Col span={(onlyWidth >= 1400 && !isFrame) ? 20 : 24} >
        <div className={isFrame ? '' : 'article-body'}>
            {!isFrame && <div className='article-title'>{divTitle}{btnEdit}</div>}
            {divAlias}
            {divIdx}
            {divAdmin}
            {divSpaces}
            {divBody}
        </div>
    </Col>

    const onCloseEdit = () => {
        setAdding(false);
        getItem();
    }
    const divAdding = adding ? <ArticleNew onClose={onCloseEdit} parentId={editParentId}/> : null;
    const divSpace = item?.spaceName && <Tag color='geekblue'>{item.spaceName}</Tag>

    return isFrame ? colBody : <>
        <Breadcrumb>
            {divSpace}
            <Breadcrumb.Item><Link to="/"><HomeOutlined /></Link></Breadcrumb.Item>
            {divBreads}
        </Breadcrumb>
        <Row>
        {onlyWidth >= 1400 && colSide}
        {colBody}
        {onlyWidth < 1400 && colSide}
        </Row>
        {divAdding}
    </>;
};

export default withRouter(Article);